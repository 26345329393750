$(document).ready(function () {
  if (getCookie("CookiesPolicy") !== "true") {
    document.getElementById("cookie-wrapper").style.display = "block";
  } else {
    document.getElementById("cookie-wrapper").style.display = "none";
  }

  $('#accept-btn').on('click', function () {
    acceptCookies();
  });
})

function acceptCookies () {
  document.getElementById("cookie-wrapper").style.display = "none";
  setCookie("CookiesPolicy", true, 30);
};

function getCookie (name) {
  let cookieValue = null;
  if (document.cookie && document.cookie !== "") {
    let cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      let cookie = cookies[i].trim();
      // Does this cookie string begin with the name we want?
      if (cookie.substring(0, name.length + 1) === name + "=") {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
};

function setCookie (name, value, days) {
  let date, expires;
  if (days) {
    date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toGMTString();
  } else {
    expires = "";
  }
  document.cookie = name + "=" + value + expires + "; path=/";
};