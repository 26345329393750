$(document).ready(function () {
  toggleLoader(false);
  $('body').css({'overflow': 'hidden'});
  $('#loader').css({'background':'#FFD200DD'})
});

function toggleLoader (status, callback) {
  callback = callback || function () {};
  if (status) {
    $('#loader').fadeIn(200, function () {
      callback();
    });
  } else {
    $('#loader').fadeOut(200, function () {
      callback();
    });
  }
}

$(document).on('click', 'a[href^="#"]', function (e) {
  e.preventDefault();
  $('html, body').stop().animate({
      scrollTop: $($(this).attr('href')).offset().top
  }, 700, 'linear');
});

$('form#rule-form').on('submit', function (e) {
  e.preventDefault();
  submitRule();
})

var submitRule = debounce(function() {
  submitForm('form#rule-form', '/regras/',
    function() {
      toggleLoader(true);
    },
    function () {
      gtag('event', 'submit', {
        'event_category': 'regra'
      });
      fbq('trackCustom', 'regraSubmetida', {});
      $('form#rule-form').fadeOut(200, function () {
        $('.feedback-success').fadeIn(200, function () {
          toggleLoader(false);
        });
      });
    },
    function () {
      toggleLoader(false);
    }, 'serialize');
}, 250, false);

function debounce(func, threshold, execAsap) {
  var timeout;
  return function debounced() {
    var obj = this,
      args = arguments;

    function delayed() {
      if (!execAsap) {
        func.apply(obj, args);
      }
      timeout = null;
    }
    if (timeout) {
      clearTimeout(timeout);
    } else if (execAsap) {
      func.apply(obj, args);
    }
    timeout = setTimeout(delayed, threshold || 100);
  };
}

module.exports = {
  debounce,
  toggleLoader,
  makeCall
};

function submitForm (form, path, load, success, error, type) {
  load();
  validateForm(form, path, success, error, type);
}

function validateForm (form, path, success, error, type) {
  $('.input-error').removeClass('input-error');
  const mailRegExp = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/;
  var valid = true;
  var msg = '';
  $.each($(form).find('input[type=email]'), function () {
    var that = $(this);
    if (!mailRegExp.test(that.val()) && that.val() != '') {
      valid = false;
      that.addClass('input-error');
      msg = 'O e-mail introduzido não é válido!';
    }
  });
  $.each($(form).find('input[required][type=text], input[required][type=password], input[required][type=email], textarea[required]'), function () {
    var that = $(this);
    if (that.val() == '') {
      valid = false;
      that.addClass('input-error');
      msg = 'Tens de preencher o(s) campo(s) assinalado(s).';
    }
  });
  $.each($(form).find('input[required][type=checkbox]'), function () {
    var that = $(this);
    if (!that.prop('checked')) {
      valid = false;
      that.addClass('input-error');
      msg = 'Tens de preencher o(s) campo(s) assinalado(s).';
    }
  });
  if (valid) {
    makeCall(form, path, success, error, type);
  } else {
    presentError(form, msg, error);
  }
}

function makeCall (form, path, success, error, type) {
  var data;
  var settings;
  if (type == 'serialize') {
    data = $(form).serialize();
    settings = {
      type: "POST",
      url: path,
      data: data
    };
  } 
  $.ajax(settings).done(function (response) {
    if (response.success) {
      success(response);
    } else {
      var msg = '';
      $.each(response.errors, function (i, v) {
        if ($.isArray(v)) {
          $.each(v, function (j, w) {
            $('[name=' + j + ']').addClass('input-error');
            msg += ('<p>' + w + '</p>');
          });
        } else {
          $('[name=' + i + ']').addClass('input-error');
          msg = v;
        }
      });

      $.each(response.field_errors, function (i, v) {
        if ($.isArray(v)) {
          $.each(v, function (j, w) {
            $('[name=' + j + ']').addClass('input-error');
            msg += ('<p>' + w + '</p>');
          });
        } else {
          $('[name=' + i + ']').addClass('input-error');
          msg = v;
        }
      });

      if (typeof response.non_field_errors != 'undefined') {
          msg = response.non_field_errors;
      }

      if (typeof response.reason != 'undefined') {
        msg = response.reason;
      }

      if (msg == '') {
        presentError(form, 'Ocorreu um erro! Por favor, volta a tentar de novo.', error);
      } else {
        presentError(form, msg, error);
      }
    }
  }).fail(function () {
    presentError(form, 'Ocorreu um erro! Por favor, volta a tentar de novo.', error);
  });
}

function presentError (form, msg, error) {
  error();
  $(form + ' .error-msg').html(msg);
  $(form + ' .error-wrapper').show();
  $('html, body').animate({
    "scrollTop": $(form + ' .error-wrapper').offset().top - 20
  }, 500);
}