import { debounce, toggleLoader } from '../common';

$('#generate-rule').on('click', function (e) {
  e.preventDefault();
  imageRule();
});

var imageRule = debounce(function() {
  getRule(
    function() {
      toggleLoader(true);
    },
    function (data) {
      gtag('event', 'gerada', {
        'event_category': 'regra'
      });
      fbq('trackCustom', 'regraGerada', {});
      toggleLoader(false);
      $('.rule-image').attr('src', data.image);
      $('.rule-image').attr('alt', data.image_alt);
      $('#facebook-share').attr('href', 'https://www.facebook.com/sharer/sharer.php?u=http%3A//natalbeirao.com/regras/?regras-imagem=' + data.uuid);
      $('#twitter-share').attr('href', 'https://twitter.com/intent/tweet?text=http%3A//natalbeirao.com/regras/?regras-imagem=' + data.uuid);
      $('#whatsapp-share').attr('href', 'https://api.whatsapp.com/send/?text=http%3A//natalbeirao.com/regras/?regras-imagem=' + data.uuid);
      $('#linkedin-share').attr('href', 'https://www.linkedin.com/shareArticle?mini=true&url=http%3A%2F%2Fnatalbeirao.com%2Fregras%2F%3Fregras-imagem%3D' + data.uuid);
      $('#download-share').attr('href', data.image);
    },
    function () {
      toggleLoader(false);
    });
}, 250, false);

function getRule(load, success, error) {
  load();
  $.get('/regras-imagem/', {} ).done(function (data) {
    $('.img-error-wrapper').hide();
    success(data);
  })
  .fail(function () {
    toggleLoader(false);
    $('.img-error-msg').html('Ocorreu um erro! Por favor tenta mais tarde.');
    $('.img-error-wrapper').show();
    $('html, body').animate({
      "scrollTop": $('.img-error-wrapper').offset().top - 20
    }, 500);
  });
};

var Config = {
  Link: "span.icial.share",
  Width: 500,
  Height: 500
};

$('a#download-share').on('click', function () {
  gtag('event', 'partilha', {
    'event_category': 'download-share'
  });
  fbq('trackCustom', 'download-share', {});
});

$('a#whatsapp-share').on('click', function (e) {
  gtag('event', 'partilha', {
    'event_category': 'whatsapp-share'
  });
  fbq('trackCustom', 'whatsapp-share', {});
});

$('a.social-share').on('click', function (e) {
  e.preventDefault();
  gtag('event', 'partilha', {
    'event_category': $(this).attr('id')
  });
  fbq('trackCustom', $(this).attr('id'), {});

  var px = Math.floor(((screen.availWidth || 1024) - Config.Width) / 2);
  var py = Math.floor(((screen.availHeight || 700) - Config.Height) / 2);

  window.open($(this).attr('href'), "social",
  "width=500,height=500" +
  ",left="+px+",top="+py+
  ",location=0,menubar=0,toolbar=0,status=0,scrollbars=1,resizable=1" );
});

const generateButton = document.getElementById('generate-rule');

if (generateButton) {
  generateButton.addEventListener('mouseover', function () {
    document.getElementById('spin').classList.add('fa-spin');
  });
  generateButton.addEventListener('mouseout', function () {
    document.getElementById('spin').classList.remove('fa-spin');
  });
}  

$(window).on('load', function () {
  // Find all YouTube videos
  var video = $(".yt-video");

  // Figure out and save aspect ratio for each video
  video.each(function() {

  $(video)
  .data('aspectRatio', this.height / this.width)

  // and remove the hard coded width/height
  .removeAttr('height')
  .removeAttr('width');

  });
  
  // When the window is resized
  $(window).resize(function() {

  var newWidth = $(".video-wrapper").width();
  
  // Resize all videos according to their own aspect ratio
  video.each(function() {

  var el = $(this);
  el
    .width(newWidth)
    .height(newWidth * el.data('aspectRatio'));

  });

  // Kick off one resize to fix all videos on page load
  }).resize();
})

// // Vanilla JS , IE10+

// const ready = function () {
//   const actionBtnCol = document.querySelectorAll('.action-btn');
//   for (const el of actionBtnCol) {
//     el.addEventListener('click', function (e) {
//       e.preventDefault();
//       changeColorDebounced(e.target);
//     });
//   }
// };

// if (document.readyState === "complete" ||
//      (document.readyState !== "loading" && !document.documentElement.doScroll)) {
//   ready();
// } else {
//   document.addEventListener("DOMContentLoaded", ready);
// }

// const changeColorDebounced = debounce(changeColor, 200);

// function changeColor(el) {
//   el.classList.toggle('change');
// }


// JQUERY
/*
import { debounce } from '../common';

$(document).ready(function () {
  $('.action-btn').on('click', function () {
    changeColorDebounced($(this));
  });
});

const changeColorDebounced = debounce(changeColor, 200);

function changeColor(jqueryElement) {
  jqueryElement.toggleClass('change');
}
*/
